<template>
  <div class="pageContol studentList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">培训管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">班级参数筛查</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox mrb">
            <div title="班级信息" class="searchboxItem ci-full">
              <span class="itemLabel">班级信息:</span>
              <el-select v-model="searchData.projectId" placeholder="请选择" remote size="small" clearable
                :remote-method="superProjectSelect" @visible-change="clearProjectSearchModel">
                <template>
                  <div class="select-header">
                    <span style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        width: 150px;
                      ">班级编号</span>
                    <span style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        margin-left: 50px;
                        width: 250px;
                      ">班级名称</span>
                  </div>
                  <div class="select-header">
                    <el-input v-model="searchData_Project.seaProjectCode" v-on:input="superProjectSelect" type="text"
                      size="small" placeholder="班级编号" clearable style="width: 150px" />
                    <el-input v-model="searchData_Project.seaProjectName" v-on:input="superProjectSelect" type="text"
                      size="small" placeholder="请输入班级名称" clearable style="margin-left: 50px; width: 250px" />
                  </div>
                </template>
                <el-option v-for="item in searchData_Project.projectList" :key="item.projectId"
                  :label="item.projectName" :value="item.projectId">
                  <span style="width: 150px; font-size: 13px">{{
                item.projectCode
              }}</span>
                  <span style="width: 250px; margin-left: 50px; font-size: 13px">{{ item.projectName }}</span>
                </el-option>
              </el-select>
            </div>
            <div title="开班机构" class="searchboxItem ci-full">
              <span class="itemLabel">开班机构:</span>
              <el-select size="small" v-model="searchData.compId" remote :remote-method="getCompanyList" filterable
                clearable placeholder="请至少输入两个字搜索">
                <el-option v-for="item in companyList" :key="item.compId" :label="item.compName"
                  :value="item.compId"></el-option>
              </el-select>
            </div>
            <div title="认证方式" class="searchboxItem ci-full">
              <span class="itemLabel">认证方式:</span>
              <el-select v-model="searchData.isIdCard" placeholder="请选择" size="small" clearable>
                <el-option v-for="item in isIdCardList" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </div>
            <div title="每日签到" class="searchboxItem ci-full">
              <span class="itemLabel">每日签到:</span>
              <el-select v-model="searchData.paraIsLoginEveryDay" placeholder="请选择" size="small" clearable>
                <el-option v-for="item in paraIsLoginEveryDayList" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </div>
            <div title="课前签到" class="searchboxItem ci-full">
              <span class="itemLabel">课前签到:</span>
              <el-select v-model="searchData.paraIsLogin" placeholder="请选择" size="small" clearable>
                <el-option v-for="item in paraIsLoginList" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </div>
          </div>
          <div class="searchbox mrb">
            <div title="课中签到" class="searchboxItem ci-full">
              <span class="itemLabel">课中签到:</span>
              <el-select v-model="searchData.paraIsSnapshots" placeholder="请选择" size="small" clearable>
                <el-option v-for="item in paraIsSnapshotsList" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </div>
            <div title="考试签到" class="searchboxItem ci-full">
              <span class="itemLabel">考试签到:</span>
              <el-select v-model="searchData.paraIsAfter" placeholder="请选择" size="small" clearable>
                <el-option v-for="item in paraIsAfterList" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </div>
            <div title="录制视频" class="searchboxItem ci-full">
              <span class="itemLabel">录制视频:</span>
              <el-select v-model="searchData.paraRecordVideo" placeholder="请选择" size="small" clearable>
                <el-option v-for="item in paraRecordVideoList" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </div>
            <div title="弹窗点名" class="searchboxItem ci-full">
              <span class="itemLabel">弹窗点名:</span>
              <el-select v-model="searchData.popupApproveEnable" placeholder="请选择" size="small" clearable>
                <el-option v-for="item in popupApproveEnableList" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </div>
            <div title="弹窗提示" class="searchboxItem ci-full">
              <span class="itemLabel">弹窗提示:</span>
              <el-select v-model="searchData.paraCountDownEnable" placeholder="请选择" size="small" clearable>
                <el-option v-for="item in paraCountDownEnableList" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </div>
          </div>
          <div class="searchbox mrb">
            <div title="顺序学习" class="searchboxItem ci-full">
              <span class="itemLabel">顺序学习:</span>
              <el-select v-model="searchData.paraOrderStudy" placeholder="请选择" size="small" clearable>
                <el-option v-for="item in paraOrderStudyList" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </div>
            <div title="特殊完成条件" class="searchboxItem ci-full">
              <span class="itemLabel">特殊完成条件:</span>
              <el-select v-model="searchData.paraCompleteConditionState" placeholder="请选择" size="small" clearable>
                <el-option v-for="item in paraCompleteConditionStateList" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </div>
            <div title="每日学习时长限制" class="searchboxItem ci-full">
              <span class="itemLabel">每日学习时长限制:</span>
              <el-select v-model="searchData.studyTimeLimitState" placeholder="请选择" size="small" clearable>
                <el-option v-for="item in studyTimeLimitStateList" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </div>
            <div title="考试次数限制" class="searchboxItem ci-full">
              <span class="itemLabel">考试次数限制:</span>
              <el-select v-model="searchData.paraExamNumLimit" placeholder="请选择" size="small" clearable>
                <el-option v-for="item in paraExamNumLimitList" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </div>
            <div title="学习时间段限制" class="searchboxItem ci-full">
              <span class="itemLabel">学习时间段限制:</span>
              <el-select v-model="searchData.studyTimeLimitSlotState" placeholder="请选择" size="small" clearable>
                <el-option v-for="item in studyTimeLimitSlotStateList" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </div>
          </div>
          <div class="searchbox">
            <div title="自动生成截屏记录" class="searchboxItem ci-full">
              <span class="itemLabel">自动生成截屏记录:</span>
              <el-select v-model="searchData.autoScreenShot" placeholder="请选择" size="small" clearable>
                <el-option v-for="item in autoScreenShotList" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </div>
            <div class="df" style="padding-right: 45px">
              <el-button type="primary" class="bgc-bv" round @click="getData()">查询</el-button>
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
              :header-cell-style="tableHeader" stripe>
              <el-table-column label="序号" align="center" type="index" :index="indexMethod" fixed />
              <el-table-column label="班级编码" align="left" show-overflow-tooltip min-width="180" prop="projectCode" />
              <el-table-column label="班级名称" align="left" show-overflow-tooltip min-width="180" prop="projectName" />
              <el-table-column label="班级状态" align="left" show-overflow-tooltip min-width="100" prop="projectState">
                <template slot-scope="scope">
                  {{ $setDictionary("PROJECTSTATE", scope.row.projectState) }}
                </template>
              </el-table-column>
              <el-table-column label="开班机构" align="left" show-overflow-tooltip min-width="200" prop="compName" />
              <el-table-column label="认证方式" align="left" show-overflow-tooltip min-width="100" prop="isIdCard">
                <template slot-scope="scope">
                  {{ scope.row.isIdCard ? '身份证照片' : '身份证号' }}
                </template>
              </el-table-column>
              <el-table-column label="每日签到" align="left" show-overflow-tooltip min-width="100" prop="graduation">
                <template slot-scope="scope">
                  {{ scope.row.paraIsLoginEveryDay ? '是' : '否' }}
                </template>
              </el-table-column>
              <el-table-column label="课前签到" align="left" show-overflow-tooltip min-width="100" prop="graduation">
                <template slot-scope="scope">
                  {{ scope.row.paraIsLogin ? '是' : '否' }}
                </template>
              </el-table-column>
              <el-table-column label="课中签到" align="left" show-overflow-tooltip min-width="100" prop="graduation">
                <template slot-scope="scope">
                  {{ scope.row.paraIsSnapshots ? '是' : '否' }}
                </template>
              </el-table-column>
              <el-table-column label="考试签到" align="left" show-overflow-tooltip min-width="100" prop="graduation">
                <template slot-scope="scope">
                  {{ scope.row.paraIsAfter ? '是' : '否' }}
                </template>
              </el-table-column>
              <el-table-column label="视频录制" align="left" show-overflow-tooltip min-width="100" prop="graduation">
                <template slot-scope="scope">
                  {{ scope.row.paraRecordVideo ? '是' : '否' }}
                </template>
              </el-table-column>
              <el-table-column label="弹窗点名" align="left" show-overflow-tooltip min-width="100" prop="graduation">
                <template slot-scope="scope">
                  {{ scope.row.popupApproveEnable ? '是' : '否' }}
                </template>
              </el-table-column>
              <el-table-column label="弹窗提示" align="left" show-overflow-tooltip min-width="100" prop="graduation">
                <template slot-scope="scope">
                  {{ scope.row.paraCountDownEnable ? '是' : '否' }}
                </template>
              </el-table-column>
              <el-table-column label="按顺序学习" align="left" show-overflow-tooltip min-width="100" prop="graduation">
                <template slot-scope="scope">
                  {{ scope.row.paraOrderStudy ? '是' : '否' }}
                </template>
              </el-table-column>
              <el-table-column label="特殊完成条件" align="left" show-overflow-tooltip min-width="120" prop="graduation">
                <template slot-scope="scope">
                  {{ scope.row.paraCompleteConditionState ? '是' : '否' }}
                </template>
              </el-table-column>
              <el-table-column label="每日学习时长限制" align="left" show-overflow-tooltip min-width="180" prop="graduation">
                <template slot-scope="scope">
                  {{ scope.row.studyTimeLimitState ? '是' : '否' }}
                </template>
              </el-table-column>
              <el-table-column label="考试次数限制" align="left" show-overflow-tooltip min-width="140" prop="graduation">
                <template slot-scope="scope">
                  {{ scope.row.paraExamNumLimit ? '是' : '否' }}
                </template>
              </el-table-column>
              <el-table-column label="学习时间段限制" align="left" show-overflow-tooltip min-width="160" prop="graduation">
                <template slot-scope="scope">
                  {{ scope.row.studyTimeLimitSlotState ? '是' : '否' }}
                </template>
              </el-table-column>
              <el-table-column label="自动生成截屏记录" align="left" show-overflow-tooltip min-width="160" prop="graduation">
                <template slot-scope="scope">
                  {{ scope.row.autoScreenShot ? '是' : '否' }}
                </template>
              </el-table-column>
              <el-table-column label="操作" align="left" show-overflow-tooltip min-width="100" fixed="right" prop="graduation">
                <template slot-scope="scope">
                  <el-button type="text" style="padding:0px 5px" size="mini"
                    @click="handleLook(scope.row)" :disabled="!scope.row.enableConfigParam">查看详情</el-button>
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
export default {
  name: "operate_classParameterScreening",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  doNotInit: true,
  data() {
    return {
      // 检索的数据
      searchData: {
        projectId: "", // 班级id
        compId: "", // 开班机构
        isIdCard: "", // 认证方式
        paraIsLoginEveryDay: "", // 每日签到
        paraIsLogin: "", // 课前签到

        paraIsSnapshots: "", // 课中签到
        paraIsAfter: "", // 考试签到
        paraRecordVideo: "", // 录制视频
        popupApproveEnable: "", // 弹窗点名
        paraCountDownEnable: "", // 弹窗提示

        paraOrderStudy: "", // 顺序学习
        paraCompleteConditionState: "", // 特殊完成条件
        studyTimeLimitState: "", // 每日学习时长限制
        paraExamNumLimit: "", // 开始次数限制
        studyTimeLimitSlotState: "", // 学习时间段限制

        autoScreenShot: "", // 自动生成截屏记录
      },
      // 班级信息 - 组件查询内容
      searchData_Project: {
        seaProjectCode: "", // 班级编码
        seaProjectName: "", // 班级名称
        projectList: [], // 班级列表
      },
      // 开班机构
      companyList: [],
      // 认证方式 - 下拉数据
      isIdCardList: [
        {
          value: true,
          label: "身份证照片"
        },
        {
          value: false,
          label: "身份证号"
        }
      ],
      // 每日签到 - 下拉数据
      paraIsLoginEveryDayList: [
        {
          value: true,
          label: "是"
        },
        {
          value: false,
          label: "否"
        }
      ],
      // 课前签到 - 下拉数据
      paraIsLoginList: [
        {
          value: true,
          label: "是"
        },
        {
          value: false,
          label: "否"
        }
      ],

      // 课中签到 - 下拉数据
      paraIsSnapshotsList: [
        {
          value: true,
          label: "是"
        },
        {
          value: false,
          label: "否"
        }
      ],
      // 考试签到 - 下拉数据
      paraIsAfterList: [
        {
          value: true,
          label: "是"
        },
        {
          value: false,
          label: "否"
        }
      ],
      // 录制视频 - 下拉数据
      paraRecordVideoList: [
        {
          value: true,
          label: "是"
        },
        {
          value: false,
          label: "否"
        }
      ],
      // 弹窗点名 - 下拉数据
      popupApproveEnableList: [
        {
          value: true,
          label: "是"
        },
        {
          value: false,
          label: "否"
        }
      ],
      // 弹窗提示 - 下拉数据
      paraCountDownEnableList: [
        {
          value: true,
          label: "是"
        },
        {
          value: false,
          label: "否"
        }
      ],

      // 顺序学习 - 下拉数据
      paraOrderStudyList: [
        {
          value: true,
          label: "是"
        },
        {
          value: false,
          label: "否"
        }
      ],
      // 特殊完成条件 - 下拉数据
      paraCompleteConditionStateList: [
        {
          value: true,
          label: "是"
        },
        {
          value: false,
          label: "否"
        }
      ],
      // 每日学习时长限制 - 下拉数据
      studyTimeLimitStateList: [
        {
          value: true,
          label: "是"
        },
        {
          value: false,
          label: "否"
        }
      ],
      // 开始次数限制 - 下拉数据
      paraExamNumLimitList: [
        {
          value: true,
          label: "是"
        },
        {
          value: false,
          label: "否"
        }
      ],
      // 学习时间段限制 - 下拉数据
      studyTimeLimitSlotStateList: [
        {
          value: true,
          label: "是"
        },
        {
          value: false,
          label: "否"
        }
      ],

      // 自动生成截屏记录 - 下拉数据
      autoScreenShotList: [
        {
          value: true,
          label: "是"
        },
        {
          value: false,
          label: "否"
        }
      ],
    };
  },
  created() {
    this.getTraintype();
    this.getData();
  },
  watch: {},
  computed: {},
  mounted() { },
  methods: {
    // 获取 - 班级信息
    superProjectSelect(e) {
      this.$post(
        "/biz/project/superSelectProject",
        {
          pageNum: 1,
          pageSize: 5,
          projectName: this.searchData_Project.seaProjectName,
          projectCode: this.searchData_Project.seaProjectCode,
        },
        3000,
        false
      ).then((res) => {
        if (res.data.list.length > 0) {
          this.searchData_Project.projectList = res.data.list;
        } else {
          this.searchData_Project.projectList = [];
        }
      });
    },
    // 获取 - 班级信息 - 清空
    clearProjectSearchModel(e) {
      if (e) {
        this.searchData_Project.seaProjectName = "";
        this.searchData_Project.seaProjectCode = "";
        this.superProjectSelect();
      }
    },
    // 获取 - 开班机构
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/getCompanyInfoByName", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.companyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.companyList = [];
      }
    },
    // 获取培训类型 - 下拉数据
    getTraintype() {
      this.$post("/sys/category/train-type/tree").then((res) => {
        if (res.status == "0") {
          this.trainTypeList = res.data;
        }
      });
    },
    // 获取列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        courseId: this.courseId,
      };
      if (this.searchData.projectId) {
        params.projectId = this.searchData.projectId;
      }
      if (this.searchData.compId) {
        params.compId = this.searchData.compId;
      }
      if (typeof this.searchData.isIdCard !='string') {
        params.isIdCard = this.searchData.isIdCard;
      }
      if (typeof this.searchData.paraIsLoginEveryDay !='string') {
        params.paraIsLoginEveryDay = this.searchData.paraIsLoginEveryDay;
      }
      if (typeof this.searchData.paraIsLogin !='string') {
        params.paraIsLogin = this.searchData.paraIsLogin;
      }

      if (typeof this.searchData.paraIsSnapshots !='string') {
        params.paraIsSnapshots = this.searchData.paraIsSnapshots;
      }
      if (typeof this.searchData.paraIsAfter !='string') {
        params.paraIsAfter = this.searchData.paraIsAfter;
      }
      if (typeof this.searchData.paraRecordVideo !='string') {
        params.paraRecordVideo = this.searchData.paraRecordVideo;
      }
      if (typeof this.searchData.popupApproveEnable !='string') {
        params.popupApproveEnable = this.searchData.popupApproveEnable;
      }
      if (typeof this.searchData.paraCountDownEnable !='string') {
        params.paraCountDownEnable = this.searchData.paraCountDownEnable;
      }

      if (typeof this.searchData.paraOrderStudy !='string') {
        params.paraOrderStudy = this.searchData.paraOrderStudy;
      }
      if (typeof this.searchData.paraCompleteConditionState !='string') {
        params.paraCompleteConditionState = this.searchData.paraCompleteConditionState;
      }
      if (typeof this.searchData.studyTimeLimitState !='string') {
        params.studyTimeLimitState = this.searchData.studyTimeLimitState;
      }
      if (typeof this.searchData.paraExamNumLimit !='string') {
        params.paraExamNumLimit = this.searchData.paraExamNumLimit;
      }
      if (typeof this.searchData.studyTimeLimitSlotState !='string') {
        params.studyTimeLimitSlotState = this.searchData.studyTimeLimitSlotState;
      }

      if (typeof this.searchData.autoScreenShot !='string') {
        params.autoScreenShot = this.searchData.autoScreenShot;
      }
      this.doFetch({
        url: "/run/project/course/param/query/pageList",
        params,
        pageNum,
      });
    },
    // 查看详情
    handleLook(row){
      this.$router.push({
        path: "/web/classparameters",
        query: {
          projectId: row.projectId,
          stu: "edit",
          classListCtrY: "classListCtrY",
          projectStudyTimeState: row.projectStudyTimeState,
          allDisabled: 'allDisabled', // 全部字段只读
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.select-header {
  font-size: 14px;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #606266;
  height: 34px;
  line-height: 34px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
}

.mrb {
  margin-bottom: 10px;
}
</style>
